
import { defineComponent, ref } from 'vue';
// import { cryptoWallet, Marketplace } from '@tradist/blockchain/frontend';
import BaseButton from '../components/Base/BaseButton.vue';
import BaseBanner from '../components/Base/BaseBanner.vue';
import BaseCard from '../components/Base/BaseCard.vue';

import NewsletterButton from '../components/Buttons/NewsletterButton.vue';

import BrushIcon from '../components/Icons/BrushIcon.vue';
import TransactionIcon from '../components/Icons/TransactionIcon.vue';
import PriceUpIcon from '../components/Icons/PriceUpIcon.vue';
import TheFooter from '../components/Layout/TheFooter.vue';
import BaseToaster from '../components/Base/BaseToaster.vue';
import BaseSelect from '../components/Base/BaseSelect.vue';
import BaseSelectItem from '../components/Base/BaseSelectItem.vue';

export default defineComponent({
	components: {
		BaseButton,
		BaseBanner,
		BaseCard,
		NewsletterButton,
		BrushIcon,
		TransactionIcon,
		PriceUpIcon,
		TheFooter,
		BaseToaster,
		BaseSelect,
		BaseSelectItem,
	},
	setup() {
		const selectData = [
			{ id: 1, text: 'Deneme 1' },
			{ id: 2, text: 'Hebele 2' },
			{ id: 3, text: 'Hubele 3' },
			{ id: 4, text: 'Deneme 4' },
			{ id: 5, text: 'Deneme 5' },
			{ id: 6, text: 'Deneme 6' },
			{ id: 7, text: 'Deneme 7' },
		];

		const selectedData = ref([]);

		const handleSelectedData = (item) => {
			selectedData.value.push(item);
		};

		return {
			selectData,
			selectedData,
			handleSelectedData,
		};
	},
});
